<template>
  <a-modal
    :visible="openInitialTopicModal"
    :footer="null"
    @cancel="closeModal"
    :header="null"
    width="47rem"
    class="initial-modal"
    :closable="false"
    :maskClosable='false'
  >
    <div v-if="(initialStep === 'true' || isOnlyJob) && !isOrganizationAccount" class="initial-form-modal-container">
      <a-form ref="workForm" :model="userWorkExperience" :rules="rules" @finish="submitWorkExperience">
        <div class="initial-form-modal-container__header">
          <h1>We are tailoring your news superintelligence environment.</h1>
          <p>Please enter your job details.</p>
          <h2 v-if="!isOnlyJob" class="steps">Step 1/2</h2>
        </div>

        <div class="initial-form-modal-container__body">
            <a-form-item name="title">
              <a-input type="text" v-model:value="userWorkExperience.title" class="initial-form-modal-container__body--input" placeholder="Job designation" />
            </a-form-item>
            <a-form-item name="organization">
              <a-input type="text" v-model:value="userWorkExperience.organization" class="initial-form-modal-container__body--input" placeholder="Organization" />
            </a-form-item>
        </div>
        <div class="initial-form-modal-container__footer">
          <a-button class="initial-form-modal-container__footer--btn" html-type="submit" :loading="workFormButtonLoading">{{ isOnlyJob ? 'Save' : 'Save and Continue'}}</a-button>
        </div>
      </a-form>
    </div>

    <div v-else-if="(initialStep === 'false' && !isOnlyJob) || isOrganizationAccount" class="initial-topics-modal-container">
      <div class="initial-topics-modal-container__header">
        <h1>We are tailoring your news superintelligence environment.</h1>
        <p>Please select the topics you are interested in</p>
        <h2 v-if="!isOrganizationAccount" class="steps">Step 2/2</h2>
      </div>
      <div ref="modalBody" class="initial-topics-modal-container__body">
        <template v-if="customKeywordList.length > 0">
          <div class="initial-topics-modal-container__body--title">
            <h1>My Topics</h1>
          </div>

          <div class="initial-topics-modal-container__body--topics" v-for="(keyword) in customKeywordList" :key="keyword.id">
            <div class="initial-topics-modal-container__body--topics--title">
              <h1>{{ keyword?.interest }}</h1>
              <p>The latest updates on major events</p>
            </div>
            <div class="initial-topics-modal-container__body--topics--switch-btn">
              <a-switch v-model:checked="keyword.checked" />
            </div>
          </div>
        </template>
        <a-skeleton
          v-if="showSkeletonLoader"
          class="initial-topics-modal-container__body--topics-skeleton"
          active
          :title="null"
          :paragraph="{ rows: 4, width: ['100%', '100%', '100%', '100%'] }"
        />
        <template v-else>
          <div class="initial-topics-modal-container__body--title initial-topics-modal-container__body--title-trending" v-if="customKeywordList.length > 0">
            <h1>Trending Topics</h1>
          </div>
          <div class="initial-topics-modal-container__body--topics" v-for="keyword in keywordsList" :key="keyword.id">
            <div class="initial-topics-modal-container__body--topics--title">
              <h1>{{ keyword?.interest }}</h1>
              <p>The latest updates on major events</p>
            </div>
            <div class="initial-topics-modal-container__body--topics--switch-btn">
              <a-switch v-model:checked="keyword.checked" />
            </div>
          </div>
        </template>
        <a-button
          class="initial-topics-modal-container__body--add-topic-btn" @click="openAddNewTopicModal()">
          <i class="icon icon-add_plus"></i> 
          Add Topic
        </a-button>
      </div>
      <div class="initial-topics-modal-container__footer">
        <p v-if="errorMsg" class="initial-topics-modal-container__footer--error">{{errorMsg}}</p>
        <a-button
          class="initial-topics-modal-container__footer--btn initial-topics-modal-container__footer--save-btn"
          :loading="actionLoader"
          @click.stop="saveKeywords"
          >Save and continue</a-button>
        <a-button
          v-if="!isOrganizationAccount"
          class="initial-topics-modal-container__footer--btn initial-topics-modal-container__footer--skip-btn"
          @click.stop="moveStepBackwards()"
          >Back</a-button>
      </div>
    </div>
  </a-modal>
  <a-modal
    :visible="visibleAddNewTopicModal"
    :footer="null"
    @cancel="closeAddNewTopicModal"
    :header="null"
    width="48rem"
    class="add-new-initial-topic-modal"
  >
    <div class="add-new-initial-topic-modal-container">
      <div class="add-new-initial-topic-modal-container__header">
        <h1>Add New Topic</h1>
      </div>
      <div class="add-new-initial-topic-modal-container__body">
        <TagifyKeywords 
          placeholder="Add new topic"
          @fetchKeywords="fetchKeywords"
        />
      </div>
      <div class="add-new-initial-topic-modal-container__footer">
        <a-button
          :loading="loading"
          class="add-new-initial-topic-modal-container__footer--btn"
          @click.stop="addCustomKeywords()"
        >
          Add
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import TagifyKeywords from '../../components/PdbComponents/TagifyKeywords.vue';

export default {
  props: {
    visible: Boolean,
    isOnlyJob:{
      type: Boolean,
      default: () => (false)
    }
  },
  data() {
    return {
      checked: false,
      keywordsList: [],
      actionLoader:false,
      showSkeletonLoader: false,
      visibleAddNewTopicModal: false,
      customKeywordList: [],
      error:{
        title:'', 
        organization:''
      },
      initialStep: '',
      userWorkExperience:{
        title:'',
        organization: '',
        present: true
      },
      rules:{
        title:[
          {
            required: true,
            message: 'Please enter your job designation.',
            trigger: 'change',
          },
        ],
        organization:[
          {
            required: true,
            message: 'Please enter organization name',
            trigger: 'change',
          },
        ],

      },
      workFormButtonLoading: false,
      errorMsg: '',
    };
  },
  components: {
    TagifyKeywords
  },
  emits: ["close-modal"],
  computed:{
    ...mapGetters({
      openInitialTopicModal: 'discover/openInitialTopicModal',
      initialJobId: 'discover/initialJobId',
      getUserAccountType: 'profile/getUserAccountType'
    }),
    isOrganizationAccount(){
      return this.getUserAccountType === 'organization'
    }
  },
  watch:{
    openInitialTopicModal:{
      async handler(isOpen){
        if (isOpen) {
          this.initialStep = localStorage.getItem('initialStep') ?? 'true'
          try{
            this.showSkeletonLoader = true
            this.keywordsList = (await this.fetchPDBInitialKeywords()).map(keyword=>({...keyword, checked:false}))
          }
          catch(err){console.log(err)}
          finally{this.showSkeletonLoader = false}
        }
      }, immediate: true
    }
  },
  methods: {
    ...mapActions({
      fetchPDBInitialKeywords: 'discover/fetchPDBInitialKeywords',
      toggleInitialTopicModal: 'discover/toggleInitialTopicModal',
      createPDBKeywords: 'discover/createPDBKeywords',
      setInitialJobId: 'discover/setInitialJobId'
    }),
    closeModal() {
      this.errorMsg = ''
      this.resetForm()
      this.toggleInitialTopicModal()
    },
    async saveKeywords(){
      try{
        this.actionLoader = true
        const selectedKeywords = [...this.keywordsList.filter(keyword=>keyword.checked).map(topic=>topic.interest), ...this.customKeywordList.filter(keyword=>keyword.checked).map(topic=>topic.interest)]
        if (selectedKeywords.length === 0 ){
          this.errorMsg = 'Please select any one of the pre listed topics or create a new one.'
          return 
        }
        await this.createPDBKeywords({included_keywords: selectedKeywords})
        this.closeModal()
        this.$showToast({message:'Topic keywords updated successfully.', type:'success'})

      }
      catch(err){
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
      }
      finally{
        this.actionLoader = false
      }
    },
    openAddNewTopicModal(){
      this.visibleAddNewTopicModal = true
    },
    closeAddNewTopicModal(){
      this.visibleAddNewTopicModal = false
      this.emitter.emit('clearFilters')
    },
    fetchKeywords(keywords){
      this.customKeywordList = this.serializeKeywords(keywords)
    },
    addCustomKeywords(){
     this.closeAddNewTopicModal()
     this.scrollToTop()
    },
    serializeKeywords(keywords=[]){
      return keywords.map((keyword, index)=>({
        id: index,
        interest: keyword.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
        checked: true
      }))
    },
    scrollToTop(){
      const modalElement = this.$refs.modalBody
      modalElement.scrollTo({ top: 0, behavior: "smooth" });
    },
    submitWorkExperience(){
      this.$refs.workForm.validate().then(async()=>{
        try{
          this.workFormButtonLoading = true
          this.initialJobId ? await this.updateExperience() : await this.addWorkExperience()

          this.initialStep = 'false'
          localStorage.setItem('initialStep', 'false')
          if (this.isOnlyJob){
            this.setInitialJobId(null)
            this.toggleInitialTopicModal()
            this.$showToast({message:'Job details added successfully.', type:'success'})
          }

        }catch(err){
          console.log(err)
        }finally{
          this.workFormButtonLoading = false
        }
      })
    }, 
    async addWorkExperience(){
      const res = (await this.$store.dispatch(
            "profile/addExperience",
            this.userWorkExperience
          ))?.data
          this.setInitialJobId(res?.id)
    },
    async updateExperience(){
      this.userWorkExperience.id = this.initialJobId
      await this.$store.dispatch(
            "profile/updateExperience",
            this.userWorkExperience
          )
    },
    moveStepBackwards(){
      localStorage.setItem('initialStep', 'true')
      this.initialStep = 'true'
    },
    resetForm(){
      this.userWorkExperience.title = ''
      this.userWorkExperience.organization = ''
    }
  },
};
</script>

<style lang="scss">
.initial-modal {
  top: 8rem !important;
  .ant-modal-content {
    background-color: $color-white;

    .ant-modal-close {
      margin: 1.9rem 2rem;
      display: none;
    }
    .ant-modal-body {
      padding: 0;
      .initial-form-modal-container {
        padding: 2.2rem 0;
        &__header {
          margin-bottom: 2.5rem;
          padding: 0 2.5rem;
          h1 {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            margin-bottom: 0;
            line-height: 3.4rem;
            color: $color-black;
            text-align: center;
            margin-bottom: 0.5rem;
          }
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: normal;
            color: $color-black;
            text-align: center;
            margin-bottom: 0;
            opacity: 0.7;
          }
        }
        &__body {
          padding: 0 2.5rem;
          .ant-form-item {
            margin-bottom: 0;
            .ant-form-item-control {
              .ant-form-item-explain {
                font-size: 1.4rem;
                font-family: $font-primary;
                line-height: normal;
                color: $color-black;
                margin: -0.8rem 0 0.6rem;
                text-align: left;
              }
              .ant-form-item-explain-error {
                color: red;
              }
            }
          }
          &--input {
            height: 4rem;
            padding: 1.2rem 1rem;
            border: 1px solid $color-dark-grey-5;
            outline: none;
            border-radius: 5px !important;
            width: 100%;
            font-size: 1.6rem;
            font-family: $font-primary;
            color: $color-black;
            margin-bottom: 1.7rem;
            box-shadow: none;
            &::placeholder {
              color: $color-black;
              opacity: 0.4;
            }
          }
          &--add-topic-btn {
            padding: 0;
            height: max-content;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            color: $color-primary;
            line-height: normal;
            cursor: pointer;
            margin: 1.5rem 0;
            box-shadow: none;
            span {
              line-height: normal;
            }
            .icon {
              font-size: 1.4rem;
              margin-bottom: 0.2rem;
            }
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar {
            width: 0.4rem;
            height: 0.4rem;
            background-color: transparent;
            border-radius: 3rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 3rem;
          }
        }
        &__footer {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 2.5rem 2.5rem 0.5rem;
          gap: 1rem;
          &--btn {
            outline: none;
            border: none;
            background-color: $color-light-grey-3;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            color: $color-black;
            height: 4rem;
            width: 30rem;
            display: block;
            margin: 0 auto;
            border-radius: 0.5rem !important;
            cursor: pointer;
            padding: 0 !important;
            box-shadow: none !important;
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 1.8rem;
                color: $color-black !important;
              }
            }
          }
        }
      }
      .initial-topics-modal-container {
        padding: 2.2rem 0;
        &__header {
          margin-bottom: 2rem;
          padding: 0 2.5rem;
          h1 {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            margin-bottom: 0;
            line-height: 3.4rem;
            color: $color-black;
            text-align: center;
            margin-bottom: 0.5rem;
          }
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: normal;
            color: $color-black;
            text-align: center;
            margin-bottom: 0;
            opacity: 0.7;
          }
        }
        &__body {
          overflow-y: auto;
          max-height: calc(100vh - 48rem);
          padding: 0 2.5rem;
          &--title {
            h1 {
              font-size: 2rem;
              font-family: $font-primary-bold;
              margin-bottom: 0;
              line-height: normal;
              color: $color-black;
            }
          }
          &--title-trending {
            margin-top: 2rem;
          }
          &--topics-skeleton {
            .ant-skeleton-content {
              .ant-skeleton-paragraph {
                margin-bottom: 0;
                li {
                  margin: 0 0 1.3rem;
                  height: 4rem;
                  &:last-child {
                    margin: 0 0 0;
                  }
                }
              }
            }
          }
          &--topics {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.2rem 0;
            border-bottom: 1px solid $color-dark-grey-5;
            &--title {
              h1 {
                font-size: 1.8rem;
                font-family: $font-primary-medium;
                margin-bottom: 0;
                line-height: normal;
                color: $color-black;
              }
              p {
                font-size: 1.4rem;
                font-family: $font-primary;
                line-height: normal;
                color: $color-black;
                margin-top: 0.7rem;
                margin-bottom: 0;
                opacity: 0.7;
              }
            }
            &--switch-btn {
              .ant-switch {
                min-width: 5rem;
                height: 3rem;
                border-radius: 100%;
                background-color: #8080803d;
                border-radius: 100px !important;
                &::after {
                  width: 2.6rem;
                  height: 2.6rem;
                }
              }
              .ant-switch-checked {
                background-color: $color-primary;
              }
            }
          }
          &--add-topic-btn {
            padding: 0;
            height: max-content;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            color: $color-primary;
            line-height: normal;
            cursor: pointer;
            margin: 1.5rem 0;
            box-shadow: none;
            span {
              line-height: normal;
            }
            .icon {
              font-size: 1.4rem;
              margin-bottom: 0.2rem;
            }
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar {
            width: 0.4rem;
            height: 0.4rem;
            background-color: transparent;
            border-radius: 3rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 3rem;
          }
        }
        &__footer {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 2.5rem 2.5rem 0.5rem;
          gap: 1rem;
          &--error {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: red;
            margin-bottom: 0;
            margin-top: -1rem;
            line-height: normal;
          }
          &--btn {
            outline: none;
            border: none;
            background-color: $color-light-grey-3;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            color: $color-black;
            height: 4rem;
            width: 30rem;
            display: block;
            margin: 0 auto;
            border-radius: 0.5rem !important;
            cursor: pointer;
            padding: 0 !important;
            box-shadow: none !important;
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 1.8rem;
                color: $color-black !important;
              }
            }
          }
          &--skip-btn {
            background-color: transparent;
            width: max-content;
            height: max-content;
            padding: 0.7rem 1rem !important;
          }
        }
      }
      .initial-form-modal-container,
      .initial-topics-modal-container {
        .steps {
          font-size: 1.6rem;
          line-height: normal;
          color: $color-black;
          margin: 1.8rem 0 0;
          font-family: $font-primary;
          opacity: 0.8;
          text-align: center;
        }
      }
    }
  }
}
.add-new-initial-topic-modal {
  top: 8rem !important;
  .ant-modal-content {
    background-color: $color-white;

    .ant-modal-close {
      margin: 1.9rem 2rem;
    }
    .ant-modal-body {
      padding: 0;
      .add-new-initial-topic-modal-container {
        padding: 2.2rem 2.5rem;
        &__header {
          margin-bottom: 2.5rem;
          padding: 0;
          h1 {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            margin-bottom: 0;
            line-height: normal;
            color: $color-black;
            text-align: left;
            margin-bottom: 0;
          }
        }
        &__body {
          .tagify {
            min-height: 4rem;
            margin-bottom: 0;
            width: 100%;
            padding: 0;
            font-size: 1.4rem;
            font-family: $font-primary;
            outline: none;
            border: 1px solid $color-dark-grey-5;
            color: $color-black;
            &__input {
              padding: 1.2rem 1.5rem;
              height: 100%;
              font-size: inherit;
            }
          }
        }
        &__footer {
          &--btn {
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            width: 9rem;
            margin-top: 5rem;
            display: block;
            margin-left: auto;
            height: 4rem;
            border: none;
            background-color: $color-primary;
            color: $color-white;
            padding: 0;
            span {
              line-height: normal;
            }
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 2rem;
                color: $color-primary;
              }
            }
          }
        }
      }
    }
  }
}
</style>
