<template>
  <div class="insight-view">
    <a-row :gutter="22" v-if="searchingArticles || parentLoading">
      <a-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        v-for="index in 16"
        :key="index"
      >
        <a-skeleton
            class="insight-view__article-card-skeleton"
            active
            :title="{ width: 'full' }"
            :paragraph="{ rows: 3, width: ['full'] }"
          />
      </a-col>
    </a-row>
    <div v-if="!searchingArticles && !parentLoading">
      <div v-if="insights.length === 0" class="insight-view__message">
        No results found
        <span v-if="$store.getters['discover/getSearchedTopic']">
          for
          <b
            ><q>{{ $store.getters["discover/getSearchedTopic"] }}</q></b
          >
        </span>
      </div>
      <a-row v-else :gutter="[18, 18]">
        <a-col
          v-for="insight in insights"
          :key="insight.id"
          :xs="24"
          :sm="12"
          :md="24"
          :lg="24"
          >
          <article-card
            :title="insight.title[0]"
            :author-name="insight.authors[0]"
            :url="insight.article_url.url"
            :sourceLogo="insight.logo[0]"
            :source="insight.article_url.source"
            :image="insight.image[0]"
            :text="insight.description[0]"
            :id="insight.id[0]"
            :date="insight.pub_date[0]"
            :ratings="insight.rating"
            :aggregatedRating="insight.aggregated_rating"
            :userReaction="insight.user_reaction"
            :totalArticleReactions="insight.article_reactions"
            :userReactionRepresentation="insight.users_reaction_representation"
            :isInsightArticle="true"
            :showShareButton="true"
            :showSaveBtn="true"
            :showDltBtn="false"
          >
          </article-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import ArticleCard from '../BaseComponents/ArticleCard.vue';

export default {
  components: {
    ArticleCard
  },
  props: {
    activeSection:{
      default : () =>('Trending News'),
      type: String
    },
    parentLoading:{
      default: () => ( false ),
      type: Boolean
    }
  },
  computed: {
    mobileView() {
      return this.$store.getters["discover/getInsights"];
    },
    desktopSkeleton() {
      const insight = this.$store.getters["discover/getInsightsSkeletonLoader"];
      const news = this.$store.getters["discover/getPDBSkeletonLoader"];
      if (this.activeSection === 'Trending News') {
        return insight
      }
      return news;
    },
    searchingArticles() {
      if (window.innerWidth <= 576) {
        return this.$store.getters["discover/getInsightsSkeletonLoader"];
      }
      return this.desktopSkeleton;
    },
    desktopView() {
      const newArticles = this.$store.getters["discover/getSearchedArticles"];
      const insights = this.$store.getters["discover/getInsights"];
      if(this.activeSection === 'Trending News'){
        return insights
      }
      return newArticles;
    },
    insights(){
      if(window.innerWidth <= 576){
        return this.mobileView;
      }
      return this.desktopView;
    }
  },
};
</script>
<style lang="scss">
.insight-view {
  &__article-card-skeleton {
    margin-bottom: 2rem;
    padding-right: 1rem;
    .ant-skeleton-content {
      .ant-skeleton-title {
        height: 300px;
        margin-top: 0;
      }
    }
  }
  &__message {
    text-align: center;
    font-size: 1.7rem;
  }
}
</style>
